document.addEventListener('turbo:load', function () {
  let lastUsedSelect = null;
  let previousValue = '';

  setTimeout(function () {
    document.querySelectorAll('.select-with-context').forEach((element) => {
      element.addEventListener('change', function (event) {
        lastUsedSelect = event.target;
        previousValue = event.target.dataset.previous;

        setTimeout(function () {
          document.addEventListener('hidden.bs.modal', function () {
            lastUsedSelect.value = previousValue;
          });
        }, 1000);
      });
    });
  }, 500);
});
