import Prefetch from 'stimulus-prefetch';

export default class extends Prefetch {
  connect() {
    super.connect();

    this.element.addEventListener('click', () => {
      this.scrollToTop();
    });
  }

  scrollToTop() {
    setTimeout(() => {
      window.scrollTo(0, 0);
    }, 250);
  }
}
