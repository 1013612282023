import Choices from 'choices.js';

document.addEventListener('turbo:load', () => {
  setTimeout(function () {
    document.querySelectorAll('.choices-select').forEach(function (element) {
      const choice = new Choices(element, {
        removeItemButton: true,
        shouldSort: false,
      });

      const isFilter = element.classList.contains('choices-filter');
      const hasSelectedElements = element.querySelectorAll('option').length >= 1;
      const button =
        element.parentElement.parentElement.parentNode.querySelector('.choices-button');

      if (isFilter && hasSelectedElements) {
        element.parentElement.querySelector('input[type=search').placeholder = '';
        button.classList.remove('d-none');
      }

      if (button) {
        button.addEventListener('click', (event) => {
          event.preventDefault();
          console.log(choice);
          choice.removeActiveItems();
        });
      }
    });

    document.querySelectorAll('.choices-filter').forEach(function (element) {
      element.addEventListener(
        'addItem',
        function (event) {
          const firstElementAdded = event.target.querySelectorAll('option').length === 1;

          if (firstElementAdded) {
            event.target.parentElement.querySelector('input[type=search').placeholder = '';
            event.target.parentElement.parentElement.parentElement.children[1].classList.remove(
              'd-none',
            );
          }
        },
        false,
      );

      element.addEventListener(
        'removeItem',
        function (event) {
          const lastElementRemoved = event.target.querySelectorAll('option').length === 0;

          if (lastElementRemoved) {
            event.target.parentElement.querySelector('input[type=search').placeholder = 'All';
            event.target.parentElement.parentElement.parentElement.children[1].classList.add(
              'd-none',
            );
          }
        },
        false,
      );
    });
  }, 1);
});
