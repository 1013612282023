import { Controller } from '@hotwired/stimulus';
import { Modal } from 'bootstrap';

export default class extends Controller {
  static targets = ['modal'];
  static values = { opened: Boolean };

  connect() {
    const backdrop = document.querySelector('.modal-backdrop');

    if (backdrop) {
      backdrop.remove();
    }

    if (this.hasModalTarget && this.hasOpenedValue && this.openedValue) {
      const modal = new Modal(this.modalTarget);
      modal.show();
    }
  }
}
