import { Turbo } from '@hotwired/turbo-rails';
import * as bootstrap from 'bootstrap';

Turbo.setConfirmMethod(async (message = undefined, formElement) => {
  const confirmAction = formElement.dataset['confirmAction'];
  if (confirmAction) {
    await renderRemoteConfirmationModal(confirmAction, formElement);
  }

  const confirmationModalElement = document.getElementById('confirmation-modal');
  const confirmationModal = new bootstrap.Modal(confirmationModalElement, {
    keyboard: false,
  });

  if (message) {
    confirmationModalElement.querySelector('.confirmation-message').innerHTML = message;
  }
  confirmationModal.toggle();

  const confirmButton = confirmationModalElement.querySelector('.confirm-button');
  const cancelButton = confirmationModalElement.querySelector('.cancel-button');
  const commentInput = confirmationModalElement.querySelector('#comment-input-area');

  return new Promise((resolve) => {
    confirmButton.addEventListener('click', () => {
      if (formElement.querySelector('.comment-field')?.length) {
        formElement.querySelector('.comment-field').value = commentInput.value;
      }

      formElement.removeAttribute('data-turbo-confirm');
      formElement.removeAttribute('data-confirm-action');
      formElement.requestSubmit();

      resolve(false);
    });

    cancelButton.addEventListener('click', () => {
      resolve(false);
    });
  });
});

async function renderRemoteConfirmationModal(confirmAction, formElement) {
  const formSearchParams = new URLSearchParams(new FormData(formElement)).toString();
  const response = await fetch(confirmAction + '?' + formSearchParams);

  const html = await response.text();
  Turbo.renderStreamMessage(html);
  // Need to sleep for at least a mili second for turbo stream to propagate
  // there must be a better solution
  await new Promise((r) => setTimeout(r, 100));
}
