import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['modalButton', 'submitButton', 'trackingForm'];

  openModal() {}

  submit() {
    this.trackingFormTarget.submit();
  }
}
