import { Controller } from '@hotwired/stimulus';

const DEBOUNCE_TIMEOUT = 1000;

export default class extends Controller {
  static targets = ['rfidData', 'scanInput', 'statusSelect', 'feedback', 'updateInput'];

  connect() {
    const rfidVerification = this.debounce(() => this.rfidVerification());
    ['keypress', 'input'].forEach((event) => {
      this.scanInputTarget.addEventListener(event, rfidVerification);
    });
  }

  rfidVerification() {
    const rfidValue = this.rfidDataTarget.dataset.rfidValue;
    const scanValue = this.scanInputTarget.value;

    if (rfidValue.toLowerCase() === scanValue.toLowerCase()) {
      this.statusSelectTarget.disabled = false;
      this.updateInputTarget.disabled = false;
      this.toggleErrorMessages(false);
    } else {
      this.statusSelectTarget.disabled = true;
      this.updateInputTarget.disabled = true;
      this.toggleErrorMessages(true);
    }
  }

  toggleErrorMessages(errorPresent) {
    const message = errorPresent
      ? 'The scanned unit does not match this rfid'
      : 'The scanned unit does match this rfid';

    if (errorPresent) {
      this.scanInputTarget.classList.add('is-invalid');
      this.scanInputTarget.classList.remove('is-valid');
    } else {
      this.scanInputTarget.classList.remove('is-invalid');
      this.scanInputTarget.classList.add('is-valid');
    }

    this.feedbackTarget.classList.remove('d-none');
    this.feedbackTarget.innerHTML = message;
  }

  debounce(func, timeout = DEBOUNCE_TIMEOUT) {
    let timer;
    return (...args) => {
      clearTimeout(timer);
      timer = setTimeout(() => {
        func.apply(this, args);
      }, timeout);
    };
  }
}
