import { Controller } from '@hotwired/stimulus';
import { Turbo } from '@hotwired/turbo-rails';

export default class extends Controller {
  static targets = ['agent', 'service'];

  updateDeliveryServices() {
    const agent = this.agentTarget.value;
    const url = `/acs_orders/${agent}/delivery_services`;

    Turbo.visit(url, { frame: this.serviceTarget });
  }
}
