document.addEventListener('turbo:load', () => {
  setTimeout(() => {
    document.querySelectorAll('.fake-radio-button').forEach((radioButton) => {
      radioButton.addEventListener('click', (event) => {
        const radioButton = event.target;
        const wasRadioChecked = !radioButton.checked;

        document.querySelectorAll('.fake-radio-button').forEach((radioButton) => {
          radioButton.checked = false;
        });

        if (wasRadioChecked) {
          radioButton.checked = false;
        } else {
          radioButton.checked = true;
        }
      });
    });
  }, 1);
});
