import { Controller } from '@hotwired/stimulus';

// Connects to data-controller="two-factor-authentication"
export default class extends Controller {
  static targets = ['generateButton'];

  connect() {
    this.generateButtonTarget.classList.add('disabled');
    this.waitTimeForResend();
  }

  waitTimeForResend() {
    const innerHtmlText = 'Resend code 00:';
    const cooldownPeriod = 60;
    const cooldownPeriodToMilliseconds = cooldownPeriod * 1000;
    let cooldown = cooldownPeriod;

    const interval = setInterval(() => {
      cooldown = cooldown - 1;
      this.generateButtonTarget.innerHTML =
        innerHtmlText + cooldown.toLocaleString('en-US', { minimumIntegerDigits: 2 });
    }, '1000');
    setTimeout(() => {
      this.generateButtonTarget.classList.remove('disabled');
      this.generateButtonTarget.innerHTML = 'Resend code';
      clearInterval(interval);
    }, cooldownPeriodToMilliseconds);
  }
}
