import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['unitLocationForm', 'unitLocationSelect', 'nextRental'];

  static unitLocationSelectInitialValue;
  static transactionStatusSelectInitialValue;

  unitLocationSelectTargetConnected(element) {
    this.unitLocationSelectInitialValue = element.querySelector('option:checked').innerText;
  }

  changeUnitLocation(event) {
    const checkedOptionText = event.target.querySelector('option:checked').innerText.trim();

    const nextRentalDate = this.hasNextRentalTarget ? this.nextRentalTarget.value : undefined;

    const modalMessage = this.modalMessage(
      'Unit Location',
      this.unitLocationSelectInitialValue,
      checkedOptionText,
      nextRentalDate,
    );

    this.unitLocationFormTarget.dataset.turboConfirm = modalMessage;

    this.unitLocationFormTarget.requestSubmit();
  }

  modalMessage(attribute, from, to, nextRentalDate) {
    let message = `Are you sure you want to change the ${attribute} from ${from} to ${to}?`;

    if (nextRentalDate) {
      const remainingDaysToRental = this.remainingDays();
      message += `<br><br>This Unit has an upcoming rental on <br>${nextRentalDate} (${remainingDaysToRental} days)`;
    }

    return message;
  }

  remainingDays() {
    const current_date = new Date().getDate();
    const next_rental_date = new Date(this.nextRentalTarget.value).getDate();

    return Math.abs(current_date - next_rental_date);
  }
}
