import { DateRangePicker } from 'vanillajs-datepicker';

document.addEventListener('turbo:load', () => {
  setTimeout(function () {
    const startDate = document.getElementById('start_date');
    const bookingDate = document.getElementById('booking_date');
    const purchaseDate = document.getElementById('purchase_date');
    const packingDate = document.getElementById('packing_date');

    if (startDate) {
      new DateRangePicker(startDate, {
        buttonClass: 'btn',
        format: 'dd/mm/yyyy',
      });
    }

    if (bookingDate) {
      new DateRangePicker(bookingDate, {
        buttonClass: 'btn',
        format: 'dd/mm/yyyy',
      });
    }

    if (purchaseDate) {
      new DateRangePicker(purchaseDate, {
        buttonClass: 'btn',
        format: 'dd/mm/yyyy',
      });
    }

    if (packingDate) {
      new DateRangePicker(packingDate, {
        buttonClass: 'btn',
        format: 'dd/mm/yyyy',
      });
    }
  }, 1);
});
