import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['field', 'submitButton'];

  edit() {
    this.fieldTarget.disabled = false;
    this.fieldTarget.classList.remove('border-0', 'bg-white');
    this.submitButtonTarget.disabled = false;
    this.submitButtonTarget.classList.remove('invisible');
  }
}
