import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['generateButton', 'printButton', 'generateForm', 'printFrame'];

  connect() {}

  submitGenerateForm() {
    this.generateFormTarget.submit();
  }

  showPrintFrame() {
    this.printFrameTarget.contentWindow.print();
  }
}
