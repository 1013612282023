import { Controller } from '@hotwired/stimulus';
import { Datepicker } from 'vanillajs-datepicker';

export default class extends Controller {
  static targets = ['datepickerContainer', 'deliveryDate', 'eventDate', 'warehouseReturnDate'];
  static SUNDAY = 0;
  static SATURDAY = 6;

  connect() {
    this.loadDatePicker();
    this.loadEventListener();
  }

  loadDatePicker() {
    const orderDispatchDate = this.datepickerContainerTarget.value;
    const today = new Date();

    this.datepicker = new Datepicker(this.datepickerContainerTarget, {
      buttonClass: 'btn',
      format: 'yyyy-mm-dd',
      startDate: orderDispatchDate,
      minDate: today,
      beforeShowDay: (date) => {
        if (this.weekend(date)) {
          return {
            enabled: false,
          };
        }

        return {
          enabled: true,
        };
      },
    });
  }

  loadEventListener() {
    this.datepickerContainerTarget.addEventListener('changeDate', () => {
      const dispatchDate = this.datepicker.getDate();
      const deliveryDate = new Date(dispatchDate);
      const warehouseReturnDate = new Date(dispatchDate);
      deliveryDate.setDate(dispatchDate.getDate() + 1);
      warehouseReturnDate.setDate(dispatchDate.getDate() + 4);
      const formattedDeliveryDate = deliveryDate.toISOString().split('T')[0];
      const formattedWarehouseReturnDate = warehouseReturnDate.toISOString().split('T')[0];

      this.deliveryDateTarget.value = formattedDeliveryDate;
      this.eventDateTarget.value = formattedDeliveryDate;
      this.warehouseReturnDateTarget.value = formattedWarehouseReturnDate;
    });
  }

  weekend(date) {
    const day = date.getDay();

    return day === this.constructor.SUNDAY || day === this.constructor.SATURDAY;
  }
}
