/* eslint-disable no-undef */

import { Controller } from '@hotwired/stimulus';
import * as bootstrap from 'bootstrap';

// Connects to data-controller="packing-list"
export default class extends Controller {
  static targets = [
    'rowCheckbox',
    'editButton',
    'dateEditField',
    'locationEditSelect',
    'statusEditSelect',
    'updatedTransactionsInput',
    'updateWarningModal',
    'warningEdit',
  ];

  connect() {
    // Initial amount of selected transaction checkboxes
    this.selectedCheckboxes = 0;
  }

  // When edit button is clicked show dynamic message regarding the changes and load checkboxs ids to form
  edit() {
    const dateChanged = this.dateEditFieldTarget.value !== '';
    const locationChanged = this.locationEditSelectTarget.value !== '';
    const statusChanged = this.statusEditSelectTarget.value !== '';

    let editWarningMessage = `You have selected ${this.selectedCheckboxes} records to update`;

    if (dateChanged) {
      const dateNewValue = this.dateEditFieldTarget.value;
      editWarningMessage = editWarningMessage + ` Packing Date to ${dateNewValue}`;
    }
    if (locationChanged) {
      const hasPreviousUpdatedField = dateChanged ? ', ' : ' ';
      const locationNewValue = this.locationEditSelectTarget.value;

      editWarningMessage =
        editWarningMessage + hasPreviousUpdatedField + `Location to ${locationNewValue}`;
    }
    if (statusChanged) {
      const hasPreviousUpdatedFields = dateChanged || locationChanged ? ', ' : ' ';
      const statusNewValue = this.statusEditSelectTarget.value;

      editWarningMessage =
        editWarningMessage + hasPreviousUpdatedFields + `Status to ${statusNewValue}`;
    }

    this.warningEditTarget.innerHTML = editWarningMessage;
    this.loadCheckboxToForm();

    this.confirmModal = new bootstrap.Modal(this.updateWarningModalTarget, {
      keyboard: false,
    });
    this.confirmModal.show();
  }

  // When the select all checkbox is clicked
  selectAllRows(event) {
    const checkAll = event.target.checked;

    this.rowCheckboxTargets.forEach(
      function (element) {
        if (element.checked !== checkAll) {
          // as checkbox has switched state check if edit button should be shown/hidden
          this.checkForChangeOnEditButtonStatus(checkAll);
          element.checked = checkAll;
          element.closest('.table-row').classList.toggle('table-active');
        }
      }.bind(this),
    );
  }

  // When individual checkbox is clicked
  rowSelected(event) {
    const checkbox = event.target;

    this.checkForChangeOnEditButtonStatus(checkbox.checked);
    event.target.closest('.table-row').classList.toggle('table-active');
  }

  // To check if Edit button should be shown or not
  checkForChangeOnEditButtonStatus(checkboxSelected) {
    // If checkbox was selected increment amount of selected checkboxes, else decrement
    if (checkboxSelected) {
      this.selectedCheckboxes++;
    } else {
      this.selectedCheckboxes--;
    }

    // If amount of selected checkboxes reaches 0 dont display edit button
    if (this.selectedCheckboxes === 0) {
      this.editButtonTarget.classList.add('d-none');
    }

    // If amount of selected checkboxes is 1 and was 0 display edit button
    if (this.selectedCheckboxes === 1 && checkboxSelected) {
      this.editButtonTarget.classList.remove('d-none');
    }
  }

  loadCheckboxToForm() {
    this.updatedTransactionsInputTarget.value = '';

    const updatedTransactionsIds = [];
    this.rowCheckboxTargets.forEach(function (element) {
      if (element.checked) {
        updatedTransactionsIds.push(element.id);
      }
    });

    this.updatedTransactionsInputTarget.value = updatedTransactionsIds;
  }

  // When form is submitted, shows loader and removes modal after the turbo frame is updated
  confirmedUpdate() {
    document.querySelector('.loader-icon').classList.remove('d-none');
    document.querySelector('.modal-body-content').classList.add('d-none');
    this.selectedCheckboxes = 0;
    this.editButtonTarget.classList.add('d-none');
  }

  closeModal() {
    document.querySelector('.loader-icon').classList.add('d-none');
    document.querySelector('.modal-body-content').classList.remove('d-none');
    this.confirmModal.hide();
  }
}
