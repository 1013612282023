import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  connect() {
    const DESKTOP_SCREEN_BREAKPOINT = 821;
    const vw = Math.max(document.documentElement.clientWidth || 0, window.innerWidth || 0);

    const sidebarState = localStorage.getItem('sidebar-state') || 'expanded';

    if (vw > DESKTOP_SCREEN_BREAKPOINT) {
      this.toggleSidebar(sidebarState);
    } else {
      this.toggleSidebar('condensed');
    }
  }

  click() {
    let currentSidebarState = localStorage.getItem('sidebar-state');

    if (currentSidebarState === null) {
      const isCondensed = this.element.getAttribute('data-leftbar-compact-mode') === 'condensed';

      currentSidebarState = isCondensed ? 'expanded' : 'condensed';
    }

    const nextSidebarState = currentSidebarState === 'expanded' ? 'condensed' : 'expanded';

    this.toggleSidebar(nextSidebarState);
    localStorage.setItem('sidebar-state', nextSidebarState);
  }

  toggleSidebar(state) {
    if (state === 'condensed') {
      this.element.classList.remove('sidebar-enable');
      this.element.setAttribute('data-leftbar-compact-mode', 'condensed');
    } else {
      this.element.classList.add('sidebar-enable');
      this.element.setAttribute('data-leftbar-compact-mode', '');
    }
  }
}
