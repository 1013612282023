import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static values = {
    text: String,
  };

  copyToClipboard() {
    navigator.clipboard.writeText(this.textValue);
  }
}
